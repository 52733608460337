import React from "react";
import { Link } from "react-router-dom";

function OperateMaintains() {
  return (
    <div
      style={{ height: "70vh" }}
      className="w-full mt-96  flex justify-center items-center gap-10"
    >
      <Link
        to="/Sub-page/Operations-Maintenance"
        className="flex flex-col items-center cursor-pointer hover:scale-105 transition-all bg-green-500 text-white p-6 rounded-lg shadow-lg w-[250px] h-[200px]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-16 w-16 mb-4"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M16 7a1 1 0 00-1 1v6a1 1 0 001 1 1 1 0 001-1V8a1 1 0 00-1-1zM5 7a1 1 0 00-1 1v6a1 1 0 001 1 1 1 0 001-1V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
        <h2 className="text-xl font-semibold"> العمليات</h2>
        <p className="text-sm"> طلبات العمليات</p>
      </Link>

      <Link
        to="/Sub-page/maintains"
        className="flex  flex-col items-center cursor-pointer hover:scale-105 transition-all  bg-red-500 text-white p-6 rounded-lg shadow-lg w-[250px] h-[200px]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-16 w-16 mb-4"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V5a1 1 0 00-1-1H8zM7 3a2 2 0 012-2h6a2 2 0 012 2v7H7V3z"
            clip-rule="evenodd"
          />
        </svg>
        <h2 className="text-xl font-semibold"> الصيانه</h2>
        <p className="text-sm"> طلبات الصيانه </p>
      </Link>
    </div>
  );
}

export default OperateMaintains;
