import { React } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import WorkDescriptionContent from "../../Component/WorkOrderDescription/WorkOrderDescription"; // Make sure this component exists

function WorkDescription() {
  return (
    <div className="apDiv">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>

        <WorkDescriptionContent />
      </div>
    </div>
  );
}

export default WorkDescription;
