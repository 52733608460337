import React from "react";

function SelectSituation({ change, value }) {
  console.log("SelectSituation", value);

  return (
    <div className="group_fe text-right w-full">
      <label>حالات الطلب</label>
      <select
        id="Situation"
        name="Situation"
        className="selectSituation"
        onChange={change}
        value={value || ""}
      >
        <option value="" disabled>
          اختر الحالة
        </option>
        <option value="pending">جاري</option>
        <option value="finish">منتهي</option>
        <option value="notFinished">لم يتم التنفيذ</option>
        {localStorage.getItem("userType") === "officemanager" && (
          <option value="approved">تم الاعتماد</option>
        )}
        {localStorage.getItem("userType") === "officemanager" && (
          <option value="udc">تم الرفع علي udc</option>
        )}
      </select>
    </div>
  );
}

export default SelectSituation;
