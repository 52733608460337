import React from "react";

function OrderTypeComponent({ value }) {
  return (
    <div className=" w-full  flex items-start justify-center gap-1 mb-2  flex-col">
      <label htmlFor="orderType">امر العمل</label>
      <input
        className="bg-gray-100 border border-gray-300 rounded-md p-2 w-full focus:outline-none  "
        readOnly
        type="text"
        value={value}
      />
    </div>
  );
}

export default OrderTypeComponent;
