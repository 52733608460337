import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UploadIcon from "../../Image/UploadIcon.png";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { domain } from "../../function/FunctionApi";

const renderUploadSection = (
  label,
  fileType,
  buttonLabel,
  handleFileChange,
  fileInputRefs,
  openFileSelector,
  fileData,
  handleApiFileDelete,
  handleFileDelete,

  multiple = true
) => (
  <div className="upload-section">
    <h4>{label}</h4>
    <div className="upload-box">
      <span className="upload-icon">
        <img
          className="w-8 bg-red-400 cursor-pointer h-8"
          src={UploadIcon}
          alt="Upload"
        />
      </span>
      <p>{buttonLabel}</p>
      <input
        type="file"
        onChange={(e) => handleFileChange(e, fileType)} // Add files to the state
        multiple={multiple}
        ref={fileInputRefs[fileType]}
        style={{ display: "none" }}
        accept=".jpg,.jpeg,.png,.pdf"
      />
      <div className="">
        <button 
        style={{ 
          backgroundColor: "green",
        }}
          type="button"
          className=" px-4 py-2 text-white !bg-green-300 rounded-md"
          onClick={() => openFileSelector(fileType)}
        >
          رفع ملف
        </button>
      </div>
    </div>

    {/* Render the file list */}
    <div className="file-list">
      {fileData[fileType]?.map((file, index) => (
        <div key={index} className="file-item">
          {file instanceof File ? (
            file.type === "application/pdf" ? (
              <embed
                src={URL.createObjectURL(file)} // Show local PDF
                type="application/pdf"
                width="100%"
                height="500px"
              />
            ) : (
              <img
                src={URL.createObjectURL(file)} // Show local image
                alt="Uploaded file"
                className="uploaded-image"
              />
            )
          ) : // Render files from the server
          file.url?.endsWith(".pdf") ? (
            <embed
              src={
                file.url.startsWith("http") ? file.url : `${domain}/${file.url}`
              }
              type="application/pdf"
              width="100%"
              height="500px"
            />
          ) : (
            <img
              src={
                file.url.startsWith("http") ? file.url : `${domain}/${file.url}`
              }
              alt="Uploaded file"
              className="uploaded-image"
            />
          )}
          <button
            className="delete-button"
            onClick={
              () =>
                file instanceof File
                  ? handleFileDelete(fileType, file) // Delete local file
                  : handleApiFileDelete(fileType, file.id) // Delete server file
            }
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </button>
        </div>
      ))}
    </div>
  </div>
);

export default renderUploadSection;
