import React from "react";

function Totals({ filteredRequests }) {
  // Helper function to ensure valid numbers
  const parseNumber = (value) =>
    isNaN(value) || value === "" ? 0 : Number(value);

  // Aggregate data
  const totalEstimatedValue = filteredRequests.reduce(
    (sum, req) => sum + parseNumber(req.estimatedValue),
    0
  );
  const totalActualValue = filteredRequests.reduce(
    (sum, req) => sum + parseNumber(req.actualValue),
    0
  );
  const totalStationNumbers = filteredRequests.reduce(
    (sum, req) => sum + (req.stationNumber ? 1 : 0),
    0
  );
  const totalExtractNumbers = new Set(
    filteredRequests.map((req) => req.extractNumber).filter(Boolean)
  ).size;

  const totalOrderDates = filteredRequests.reduce(
    (sum, req) => sum + (req.orderDate ? 1 : 0),
    0
  );
  const totalReceiveDates = filteredRequests.reduce(
    (sum, req) => sum + (req.receiveDateTime ? 1 : 0),
    0
  );

  // Count unique offices and work order types
  const officeCounts = [
    ...new Set(filteredRequests.map((req) => req.office).filter(Boolean)),
  ].length;
  const workOrderTypeCounts = [
    ...new Set(filteredRequests.map((req) => req.orderType).filter(Boolean)),
  ].length;

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-2">إجمالي البيانات</h2>
      <table className="w-full border border-gray-300 text-sm text-right rtl">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-2 py-1 bg-green-700  rounded text-center text-white">
              عدد أوامر العمل
            </th>

            <th className="border px-2 py-1 bg-green-700  rounded text-center text-white">
              إجمالي القيمة التقديريه
            </th>
            <th className="border px-2 py-1 bg-green-700  rounded text-center text-white">
              إجمالي القيمة الفعلية
            </th>
            <th className="border px-2 py-1 bg-green-700  rounded text-center text-white">
              عدد أرقام المستخلص
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-2 py-1 text-center">
              {workOrderTypeCounts}
            </td>
            <td className="border px-2 py-1 text-center">
              {totalEstimatedValue.toFixed(2)}
            </td>
            <td className="border px-2 py-1 text-center">{totalActualValue.toFixed()}</td>
            <td className="border px-2 py-1 text-center">
              {totalExtractNumbers}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Totals;
