import { FaFileExport, FaPrint, FaTrash } from "react-icons/fa";

const ExportPrintButtons = ({
  selectedRequests,
  setIsModalOpen,
  setIsPrint,
  DeleteAll,
}) => {
  if (selectedRequests.length === 0) return null;

  return (
    <div className="div-open-modal">
      <div className="flex items-center gap-4 justify-between">
        <button
          className="open-modal-button flex items-center gap-2"
          onClick={() => {
            setIsModalOpen(true);
            setIsPrint(false);
          }}
        >
          <FaFileExport />
          تصدير
        </button>

        <button
          className="open-modal-button flex items-center gap-2"
          onClick={() => {
            setIsModalOpen(true);
            setIsPrint(true);
          }}
        >
          <FaPrint />
          طباعة
        </button>
        <button
          className=" bg-red-600 p-2 rounded-md text-white"
          onClick={() => {
            DeleteAll();
          }}
        >
          <FaTrash className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default ExportPrintButtons;
