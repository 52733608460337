import React from "react";

function SelectSituation1({ change, value }) {
  console.log("SelectSituation", value);

  return (
    <div className="group_fe w-full text-right">
      <label>موقف التنفيذ</label>
      <select
        id="Situation1"
        name="Situation1"
        className="selectSituation"
        onChange={change}
        value={value || ""}
      >
        <option value="" disabled>
          اختر موقف التنفيذ
        </option>

        <option value="تم تسليم المقاول">تم تسليم المقاول</option>
        <option value="تم الاستلام من المقاول">تم الاستلام من المقاول</option>
        <option value="تم إصدار شهادة إنجاز">تم إصدار شهادة إنجاز</option>
        <option value="منتهي مستخلص">منتهي مستخلص</option>
      </select>
    </div>
  );
}

export default SelectSituation1;
