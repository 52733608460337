import Swal from "sweetalert2";
import * as XLSX from "xlsx";

const useProjectPopup = () => {
  const exportToExcel = (project) => {
    const data = [
      {
        "رقم أمر العمل": project.faultNumber || "N/A",
        "كود الأمر": project.orderCode || "N/A",
        "نوع أمر العمل": project.workOrderType || "N/A",
        "نوع المشروع": project.type || "N/A",
        "الفرع": project.branchName || "N/A",
        "الحالة": project.isArchived ? "تحت التنفيذ" : "تم التنفيذ",
        "المكتب": project.office || "N/A",
        "تاريخ استلام أمر العمل": new Date(project.receiveDateTime).toLocaleDateString("ar-EG"),
        "الحي": project.district || "N/A",
        "الوضع": project.situation || "N/A",
        "الوصف": project.workDescription || "N/A",
        "القيمة التقديرية": project.estimatedValue || "N/A",
        "القيمة الفعلية": project.actualValue || "N/A",
        "رقم المحطة": project.stationNumber || "N/A",
        "الاستشاري": project.consultant || "N/A",
        "المقاول": project.contractor || "N/A",
        "المستخدم": project.userName || "N/A",
        "هل يوجد مخالفات أمان؟": project.safetyViolationsExist ? "نعم" : "لا",
        "الملاحظات": project.note || "لا يوجد",
      },
    ];
  
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "تفاصيل المشروع");
    XLSX.writeFile(workbook, `تفاصيل_المشروع_${project.faultNumber || "N/A"}.xlsx`);
  };
  

  const printPDF = (project) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html lang="ar">
      <head>
        <meta charset="UTF-8">
        <title>تفاصيل المشروع</title>
        <style>
          body { font-family: Arial, sans-serif; direction: rtl; text-align: right; padding: 20px; }
          h2 { text-align: center; }
          table { width: 100%; border-collapse: collapse; margin-top: 20px; }
          th, td { border: 1px solid black; padding: 8px; }
          th { background-color: #f2f2f2; }
        </style>
      </head>
      <body>
        <h2>تفاصيل المشروع</h2>
        <table>
          <tr><th>الحقل</th><th>القيمة</th></tr>
          <tr><td>رقم أمر العمل</td><td>${project.faultNumber || "N/A"}</td></tr>
          <tr><td>كود الأمر</td><td>${project.orderCode || "N/A"}</td></tr>
          <tr><td>نوع أمر العمل</td><td>${project.workOrderType || "N/A"}</td></tr>
          <tr><td>نوع المشروع</td><td>${project.type || "N/A"}</td></tr>
          <tr><td>الفرع</td><td>${project.branchName || "N/A"}</td></tr>
          <tr><td>الحالة</td><td>${project.isArchived ? "تحت التنفيذ" : "تم التنفيذ"}</td></tr>
          <tr><td>المكتب</td><td>${project.office || "N/A"}</td></tr>
          <tr><td>تاريخ استلام أمر العمل</td><td>${new Date(project.receiveDateTime).toLocaleDateString("ar-EG")}</td></tr>
          <tr><td>الحي</td><td>${project.district || "N/A"}</td></tr>
          <tr><td>الوضع</td><td>${project.situation || "N/A"}</td></tr>
          <tr><td>الوصف</td><td>${project.workDescription || "N/A"}</td></tr>
          <tr><td>الرقم التقديري</td><td>${project.estimatedValue || "N/A"}</td></tr>
          <tr><td>القيمة الفعلية</td><td>${project.actualValue || "N/A"}</td></tr>
          <tr><td>رقم المحطة</td><td>${project.stationNumber || "N/A"}</td></tr>
          <tr><td>الاستشاري</td><td>${project.consultant || "N/A"}</td></tr>
          <tr><td>المقاول</td><td>${project.contractor || "N/A"}</td></tr>
          <tr><td>المستخدم</td><td>${project.userName || "N/A"}</td></tr>
          <tr><td>الصورة الشخصية</td><td><img src="${project.userImage}" alt="User Image" width="50"></td></tr>
          <tr><td>هل يوجد مخالفات أمان؟</td><td>${project.safetyViolationsExist ? "نعم" : "لا"}</td></tr>
          <tr><td>الملاحظات</td><td>${project.note || "لا يوجد"}</td></tr>
        </table>
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  

  const handShow = (project) => {
    Swal.fire({
      title: "تفاصيل المشروع",
      html: `
        <div style="text-align: right; direction: rtl;">
          <p><strong>رقم أمر العمل:</strong> ${project.faultNumber || project.orderNumber}</p>
          <p><strong>نوع أمر العمل:</strong> ${project.workOrderType || "N/A"}</p>
          <p><strong>القسم:</strong> ${project.type || "N/A"}</p>
          <p><strong>الفرع:</strong> ${project.branchName || "N/A"}</p>
          <p><strong>الحالة:</strong> ${project.isArchived ? "تحت التنفيذ" : "تم التنفيذ"}</p>
          <p><strong>المكتب:</strong> ${project.office || "N/A"}</p>
          <p><strong>تاريخ استلام أمر العمل:</strong> ${new Date(project.receiveDateTime).toLocaleDateString("ar-EG")}</p>
          <p><strong>الحي:</strong> ${project.district || "N/A"}</p>
          <p><strong>الاستشاري:</strong> ${project.consultant || "N/A"}</p>
          <p><strong>المقاول:</strong> ${project.contractor || "N/A"}</p>
          <p><strong>مدة التنفيذ:</strong> ${project.durationOfImplementation || "N/A"} يوم</p>
          <p><strong>القيمة التقديرية:</strong> ${project.estimatedValue || "N/A"}</p>
          <p><strong>رقم الاستخراج:</strong> ${project.extractNumber || "N/A"}</p>
          <p><strong>الوصف:</strong> ${project.workDescription || "N/A"}</p>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: "تصدير إلى Excel",
      cancelButtonText: "طباعة PDF",
      preConfirm: () => exportToExcel(project),
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        printPDF(project);
      }
    });
  };
  return { handShow };
};

export default useProjectPopup;
