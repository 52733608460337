import React, { useEffect, useState } from "react";
import projectImage1 from "../../Image/Rectangle 34.png";
import noDataImage from "../../Image/App Illustrations.jpg";
import { Url } from "../../function/FunctionApi";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";
import "./Projects.css";
import { officeNameMap } from "../../util/officeConstants";
import Loading from "./Loading";
import CompletedUi from "./ProjectsCards";
import StatusSelection from "./StatusSelection";
import { useLocation } from "react-router-dom";
import axios from "../../api/client";
const Projects = ({ userData }) => {
  const [isChoose, setChoose] = useState(false);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Parse query params using URLSearchParams
    const queryParams = new URLSearchParams(location.search);
    const queryStatus = queryParams.get("status");

    // Set the status from the query parameter
    if (queryStatus) {
      setStatus(queryStatus);
    }
  }, [location]);
  const [office, setOffice] = useState("");

  useEffect(() => {
    const filterProjects = () => {
      console.log(office);
      let filtered = projects;

      if (status) {
        switch (status) {
          case "completed":
            filtered = filtered.filter(
              (project) => project.situation === "finish"
            );
            break;
          case "notcompleted":
            filtered = filtered.filter(
              (project) =>
                project.situation === "pending" ||
                project.situation === "notFinished"
            );
            break;
          case "approved":
            filtered = filtered.filter(
              (project) => project.situation === "approved"
            );
            break;
          case "udc":
            filtered = filtered.filter(
              (project) => project.situation === "udc"
            );
            break;
          default:
            // If no status is selected, no filtering on situation
            break;
        }
      }

      // If 'office' is "الجميع", no need to filter by office
      if (office !== "الجميع") {
        filtered = filtered.filter((project) =>
          project.office.includes(office)
        );
      }

      return filtered;
    };

    setFilteredProjects(filterProjects());
  }, [office, status, projects]);

  console.log(filteredProjects);
  useEffect(() => {
    console.log(userData?.branchName);
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const apiUrl = "OrdersinHome/all-projects-inHome";
        const params = {
          branchName: userData?.branchName,
        };

        const response = await axios.get(`api/${apiUrl}`, { params });
        const projectData = [
          ...(response.data.MaintenanceProjects || []).map((project) => ({
            ...project,
            type: "الصيانه",
          })),
          ...(response.data.NewProjects || []).map((project) => ({
            ...project,
            type: "المشاريع",
          })),
          ...(response.data.OperationProjects || []).map((project) => ({
            ...project,
            type: "العمليات",
          })),
        ];

        setProjects(projectData);
        setFilteredProjects(projectData);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    if (userData && userData.branchName) {
      fetchProjects();
    }
  }, [userData]);

  useEffect(() => {
    let filtered = projects;
    console.log(status);

    if (status) {
      switch (status) {
        case "completed":
          filtered = filtered.filter(
            (project) => project.situation === "finish"
          );
          break;
        case "notcompleted":
          filtered = filtered.filter(
            (project) =>
              project.situation === "pending" ||
              project.situation === "notFinished"
          );
          break;
        case "approved":
          filtered = filtered.filter(
            (project) => project.situation === "approved"
          );
          break;
        case "udc":
          filtered = filtered.filter((project) => project.situation === "udc");
          break;
        default:
          break;
      }
    }

    // If searchTerm is empty, set filtered projects directly
    if (!searchTerm.trim()) {
      setFilteredProjects(filtered);
      return;
    }

    // Convert searchTerm to lowercase for case-insensitive matching
    const searchTermLower = searchTerm.toLowerCase();

    // Filter based on search term
    const results = filtered.filter((project) => {
      return [
        project.orderNumber?.toString(),
        project.orderType,
        project.faultNumber?.toString(),
        project.appUserId,
        project.office,
        project.consultant,
        project.contractor,
        project.district,
        project.estimatedValue?.toString(),
        project.extractNumber?.toString(),
        project.faultType,
        project.orderCode,
        project.orderDate,
        project.projectType,
        project.resources,
        project.userName,
      ]
        .map((value) => (value ?? "").toLowerCase())
        .some((value) => value.includes(searchTermLower));
    });

    setFilteredProjects(results);
  }, [searchTerm, projects, status]);

  const handleDelete = async (projectId, projectType) => {
    console.log(projectType);
    const getProjectTypeRoute = (orderType) => {
      switch (orderType) {
        case "المشاريع الخاصة":
          return "privateproject";
        case "المشاريع":
          return "newproject";
        case "العمليات":
          return "operation";
        default:
          return "maintains";
      }
    };

    const projectTypeRoute = getProjectTypeRoute(projectType);

    const result = await Swal.fire({
      title: "هل أنت متأكد؟",
      text: "لا يمكنك التراجع عن هذا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "نعم، احذف!",
      cancelButtonText: "لا، تراجع!",
    });

    if (result.isConfirmed) {
      try {
        const apiUrl = `Search/delete-by-orderidWithType?orderId=${projectId}&type=${projectTypeRoute}`;
        await axios.delete(`${Url}${apiUrl}`);
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.orderNumber !== projectId)
        );
        setFilteredProjects((prevFiltered) =>
          prevFiltered.filter((project) => project.orderNumber !== projectId)
        );
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.faultNumber !== projectId)
        );

        setFilteredProjects((prevFiltered) =>
          prevFiltered.filter((project) => project.faultNumber !== projectId)
        );

        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم حذف الطلب بنجاح.",
          showConfirmButton: false,
          timer: 1500,
        });

        window.location.reload();
      } catch (err) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "حدث خطأ أثناء حذف الطلب.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  if (loading) {
    return <Loading searchTerm={searchTerm} setSearchTerm={setSearchTerm} />;
  }

  if (error) return <div>Error: {error}</div>;
  console.log(filteredProjects);

  return (
    <div
      className="latest-projects-container projects-page min-h-screen"
      dir="rtl"
    >
      {isChoose === true ? (
        <CompletedUi
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filteredProjects={filteredProjects}
          noDataImage={noDataImage}
          office={office}
          setOffice={setOffice}
          setChoose={setChoose}
          projectImage1={projectImage1}
          handleDelete={handleDelete}
        />
      ) : (
        <StatusSelection setChoose={setChoose} />
      )}
    </div>
  );
};

export default Projects;
