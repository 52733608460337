import React from "react";
import { AiOutlineLink } from "react-icons/ai";

function InputCoordinates({ change, value }) {
  const handleOpenMap = () => {
    if (value) {
      window.open(`${value}`, "_blank");
    }
  };

  return (
    <div className="group_fe flex gap-2 w-full flex-col items-start">
      <label>الإحداثيات</label>
      <div className="flex w-full items-center gap-2">
        <input
          type="text"
          className="w-full"
          name="Coordinates"
          placeholder="الإحداثيات"
          value={value || ""}
          onChange={change}
          required
        />
        {value && (
          <button
            onClick={handleOpenMap}
            className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            <AiOutlineLink size={20} />
          </button>
        )}
      </div>
    </div>
  );
}

export default InputCoordinates;
