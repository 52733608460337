import React, { useEffect, useState } from "react";
import axios from "axios";
import projectImage1 from "../../../Image/Rectangle 34.png";
import noDataImage from "../../../Image/App Illustrations.jpg";
import { Url } from "../../../function/FunctionApi";
import { Link } from "react-router-dom";
import moment from "moment-hijri";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { officeNameMap } from "../../../util/officeConstants";
import temb from "../../../Image/Rectangle 34.png";
import SituationOptions from "../../../util/Situation";
import useProjectPopup from "../../../hooks/UsePorjectPopup";
import UploadZipButton from "../../UploadZipButton/UploadZipButton";

const Projects = ({ Name, Namepage, userData }) => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const { handShow } = useProjectPopup();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const apiUrlMap = {
          Subscribers: "OrderForSubscribe/filter-orders",
          "Operations-Maintenance":
            "OperationsAndMaintenaceRequest/filter-orders",
          Projects: "NewProject/filter-orders",
          "special-projects": "PrivateProject/filter-orders-privateproject",
          maintains: "OrderMain/filter-orders",
        };

        const apiUrl = apiUrlMap[Name];
        if (!apiUrl) throw new Error("Invalid Name parameter");

        const params = {
          isArchive: Namepage === "Archived",
          isCompleted: Namepage === "Completed",
          branchName: userData.branchName,
        };

        const response = await axios.get(`${Url}${apiUrl}`, {
          params,
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        });
        console.log(response);
        console.log("url++++++++++++++++++++++++++++");
        console.log(`${Url}${apiUrl}`);
        const projectData = Array.isArray(response.data)
          ? response.data
          : response.data?.data || [];
        setProjects(projectData);
        setFilteredProjects(projectData);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [Name, Namepage, userData]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = projects.filter((project) =>
        (project.faultNumber || project.orderNumber)
          ?.toString()
          .includes(searchQuery)
      );
      setFilteredProjects(filtered);
    } else {
      setFilteredProjects(projects);
    }
  }, [searchQuery, projects]);

  const renderSkeletonLoader = () => (
    <div className="cards-container">
      {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
        <div key={index} className="project-card">
          <Skeleton height={200} width={250} />
          <div className="project-info">
            <h3 className="project-title">
              <Skeleton width={150} />
            </h3>
            <p className="order-number">
              <Skeleton width={100} />
            </p>
            <p className="project-date">
              <Skeleton width={80} />
            </p>
            <Skeleton height={30} width={120} />
          </div>
        </div>
      ))}
    </div>
  );
  const handleDelete = async (projectId, projectType) => {
    const getProjectTypeRoute = (orderType) => {
      switch (orderType) {
        case "maintains":
          return "maintains";
        case "Operations-Maintenance":
          return "operation";
        case "special-projects":
          return "privateproject";
        default:
          return "newproject";
      }
    };

    const projectTypeRoute = getProjectTypeRoute(projectType);
    console.log(projectId, "from............");
    const result = await Swal.fire({
      title: "هل أنت متأكد؟",
      text: "لا يمكنك التراجع عن هذا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "نعم، احذف!",
      cancelButtonText: "لا، تراجع!",
    });

    if (result.isConfirmed) {
      try {
        const apiUrl = `Search/delete-by-orderidWithType?orderId=${projectId}&type=${projectTypeRoute}`;
        await axios.delete(`${Url}${apiUrl}`);
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.faultNumber !== projectId)
        );
        setFilteredProjects((prevFiltered) =>
          prevFiltered.filter((project) => project.faultNumber !== projectId)
        );
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.orderNumber !== projectId)
        );
        setFilteredProjects((prevFiltered) =>
          prevFiltered.filter((project) => project.orderNumber !== projectId)
        );
        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم حذف الطلب بنجاح.",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "حدث خطأ أثناء حذف الطلب.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    window.location.reload();
  };

  if (loading)
    return (
      <div className="latest-projects-container" dir="rtl">
        <div className="container">{renderSkeletonLoader()}</div>
      </div>
    );

  if (error)
    return (
      <div className="error-message" dir="rtl">
        حدث خطأ: {error}
      </div>
    );

  if (filteredProjects.length === 0) {
    return (
      <>
        <div className="latest-projects-container" dir="rtl">
          <div className="container">
            <div className="search-container ">
              <input
                type="text"
                placeholder="ابحث برقم الطلب"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <h4> عدد الطلبات ( {filteredProjects.length} )</h4>
            </div>
            <div className="NotFoundProject" dir="rtl">
              <img
                src={noDataImage}
                alt="No data available"
                className="no-data-image"
              />
              <p>لا يوجد بيانات للعرض</p>
            </div>
          </div>
        </div>
      </>
    );
  }
  console.log(filteredProjects);
  return (
    <div className="latest-projects-container" dir="rtl">
      <div className="container">
        <div className="search-container">
          <input
            type="text"
            placeholder="ابحث برقم الطلب"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <h4> عدد الطلبات ( {filteredProjects.length} )</h4>
        </div>
        <div className="cards-container">
          {filteredProjects.map((project) => (
            <div key={project.id} className="project-card ">
              <div className="relative">
                <div className="flex w-full items-center m-2 justify-between">
                  <button
                    className="bg-green-600 p-1 mx-3 rounded-full"
                    onClick={() => handShow(project)}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="text-white text-xl"
                    />
                  </button>

                  {/* <UploadZipButton project={project} /> */}
                </div>
              </div>
              <div className="project-info">
                <h3 className="project-title">
                  {project.WorkOrderType || null}
                </h3>

                {project.type === "المشاريع الخاصة" ? (
                  // Display properties for "المشاريع الخاصة"
                  <>
                    <p>اسم العميل: {project.customer}</p>
                    <p>اسم المقاول: {project.contractor}</p>
                    <p>المنطقة: {project.district}</p>
                    <p>رقم المحطة: {project.stationNumber}</p>
                    <p>
                      تاريخ الطلب:{" "}
                      {new Date(project.orderDate).toLocaleDateString()}
                    </p>
                  </>
                ) : (
                  <>
                    <p>الفرع: {project.branchName || project.orderNumber}</p>
                    <p>
                      موقف التنفيذ:{" "}
                      {project.situation === "finish"
                        ? "تم التنفيذ"
                        : project.situation === "notFinished"
                        ? "لم يتم التنفيذ"
                        : "جاري"}
                    </p>
                    <p>المكتب: {project.office}</p>{" "}
                    <p> القسم: {project.type || ""} </p>
                    <p>
                      {" "}
                      رقم امر العمل:{" "}
                      {project.faultNumber || project.orderNumber}{" "}
                    </p>
                    <p>
                      {" "}
                      نوع امر العمل:{" "}
                      {project.workOrderType || project.orderNumber}{" "}
                    </p>
                    <p>
                      {" "}
                      امر العمل:{" "}
                      {project.workOrderType + "-" + project.faultNumber}
                    </p>
                    <p> الفرع: {project.branchName || project.orderNumber} </p>
                    <p>
                      موقف المشروع:{" "}
                      {SituationOptions.get(project.situation)?.label || ""}
                    </p>
                    <p>
                      الحاله:
                      {project.isArchived === true
                        ? "تحت التنفيذ"
                        : "تم التنفيذ"}
                    </p>
                    <p className="project-date">
                      تاريخ استلام امر العمل:
                      {new Date(project.receiveDateTime).toLocaleDateString()}
                    </p>
                  </>
                )}

                <div className="buttonUpdeteDelete">
                  {/* Conditional rendering for the Link component based on the `Name` */}
                  <Link
                    to={`/${
                      Name === "maintains"
                        ? "maintain-projects"
                        : Name === "Operations-Maintenance"
                        ? "operations-maintenance"
                        : Name === "special-projects"
                        ? "special-projects"
                        : "Request-projects"
                    }/${project.id}`}
                    className="view-project-button"
                  >
                    تعديل الطلب
                  </Link>

                  {/* Delete button */}
                  <button
                    className="delete-project-button"
                    onClick={() => {
                      console.log(project.projectName);

                      // Conditional handling for delete based on the `Name`
                      if (Name === "special-projects") {
                        handleDelete(project.id, Name);
                      } else {
                        console.log("not special-projects");
                        handleDelete(project.id, Name);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
