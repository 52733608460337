import React, { useEffect, useState } from "react";
import axios from "axios";
import { Url } from "../../function/FunctionApi";

const SelectJobDescription = ({ value, onChange, workOrderType }) => {
  const [jobDescriptions, setJobDescriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!workOrderType) {
      setJobDescriptions([]); // Clear descriptions when workOrderType is empty
      return;
    }

    const fetchJobDescriptions = async () => {
      try {
        setLoading(true);
        let response;
        if (workOrderType === "all") {
          response = await axios.get(`${Url}JobDescription`);
        } else {
          response = await axios.get(
            `${Url}JobDescription/workerOrderType/${workOrderType.trim()}`
          );
        }

        if (response.status === 200 && response.data.data) {
          setJobDescriptions(response.data.data);
        } else {
          setJobDescriptions([]);
        }
      } catch (error) {
        setJobDescriptions([]);
        console.error("Error fetching job descriptions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDescriptions();
  }, [workOrderType]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-start w-full lg:w-[48%]">
      <label htmlFor="jobDescription">وصف العمل</label>
      <select
        id="WorkDescription"
        name="WorkDescription"
        value={value}
        onChange={onChange}
        required
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
      >
        <option value="" disabled>
          اختر وصف العمل
        </option>
        {jobDescriptions.map((description) => (
          <option key={description.id} value={description.name}>
            {description.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectJobDescription;
