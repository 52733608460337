import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import axios from "axios";
import { Url } from "../../function/FunctionApi";

function BranchTabs({ office, setOffice }) {
  const branchId = localStorage.getItem("branchId") || 1;
  const [offices, setOffices] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0); // Default: "الجميع"

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await axios.get(`${Url}Office/offices/${branchId}`);
        if (response.data.statusCode === 200 && response.data.data) {
          setOffices(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching offices:", error);
      }
    };

    fetchOffices();
  }, [branchId]);

  return (
    <Tabs
      value={selectedTab}
      onChange={(event, newValue) => {
        setSelectedTab(newValue);
        const selectedOfficeName =
          newValue === 0 ? "الجميع" : offices[newValue - 1].name; // Adjust index
        setOffice(selectedOfficeName);
      }}
      aria-label="Branch Tabs"
      centered
    >
      <Tab sx={{ color: "#000", fontSize: "18px" }} label="الجميع" />
      {offices.map((office, index) => (
        <Tab
          key={office.id}
          sx={{ color: "#000", fontSize: "18px" }}
          label={office.name}
        />
      ))}
    </Tabs>
  );
}

export default BranchTabs;
