import React from "react";

const PowerBIEmbed = () => {
  return (
    <div className="w-full flex justify-center h-full bg-red-500">
      <iframe
        title="Rasm Live Report  5"
        className="rounded-lg shadow-lg  w-full h-full"
        src="https://app.powerbi.com/view?r=eyJrIjoiNDQwZGI2MDQtOWJkMy00YjQzLWE3Y2MtNDgyMDdkNDAzNjAwIiwidCI6ImRlZGRkNTE5LWM4MTMtNDRlOC04OGVhLTZhZjE2ZWJmNDdhMSIsImMiOjl9&embedImagePlaceholder=true"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default PowerBIEmbed;
