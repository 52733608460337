import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTrashCan,
  faThLarge,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import BranchTabs from "../../Component/BranceTaps/BranchTaps";
import SituationOptions from "../../util/Situation";
import useProjectPopup from "../../hooks/UsePorjectPopup";
import img from "../../Image/Rectangle 34.png";
import UploadZipButton from "../../Component/UploadZipButton/UploadZipButton";

const CompletedUi = ({
  searchTerm,
  setSearchTerm,
  filteredProjects,
  noDataImage,
  office,
  setOffice,
  setChoose,
  handleDelete,
}) => {
  const { handShow } = useProjectPopup();
  const [isGridView, setIsGridView] = useState(true);
  const navigate = useNavigate();

  const removeQueryParam = () => { 
    setChoose(false)
    // Remove the `status` query parameter from the URL
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("status");

    // Navigate to the updated URL without the query parameter
    navigate(currentUrl.pathname + currentUrl.search);
  };
  return (
    <div className="container mx-auto p-4">
      {/* Top Section */}
      <div className="flex flex-wrap justify-between items-center mb-4 gap-4">
        <input
          type="text"
          placeholder="ابحث عن مشروع..."
          className="border p-2 rounded-md w-1/3 min-w-[200px]"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <h4 className="text-gray-700 font-medium">
          عدد الطلبات ({filteredProjects.length})
        </h4>
        <div className="flex gap-4">
          <button
            onClick={() => setIsGridView(!isGridView)}
            className="p-2 border rounded-md hover:bg-gray-100 transition"
          >
            <FontAwesomeIcon icon={isGridView ? faBars : faThLarge} />
          </button>
          <button
            onClick={() => removeQueryParam()}
            className="p-2 border rounded-md text-indigo-500 hover:bg-indigo-50 transition"
          >
            العوده
          </button>
        </div>
      </div>

      {/* No Data Found */}
      {filteredProjects.length === 0 && (
        <div className="text-center" dir="rtl">
          <img
            src={noDataImage}
            alt="No data available"
            className="mx-auto h-40"
          />
          <p>
            {searchTerm
              ? "لا توجد مشاريع مطابقة لبحثك."
              : "لا يوجد بيانات للعرض"}
          </p>
        </div>
      )}

      {/* Branch Tabs */}
      <BranchTabs value={office} setOffice={setOffice} />

      {/* Projects List */}
      <div
        className={
          isGridView
            ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6"
            : "flex flex-col space-y-6 mt-6"
        }
      >
        {filteredProjects.map((project, index) => (
          <div
            key={index}
            className={`bg-white shadow-lg rounded-xl overflow-hidden relative ${
              isGridView ? "" : "flex items-center "
            }`}
          >
            <div className="relative">
              {/* <img
                src={img}
                alt={project.faultType || "Project Image"}
                className={
                  !isGridView
                    ? "w-full h-full object-cover"
                    : "w-48 h-32 object-cover rounded-lg mr-4"
                }
              /> */}

              <div className="flex items-center m-2 justify-between">
                <button
                  className="bg-green-600 p-1 mx-3 rounded-full"
                  onClick={() => handShow(project)}
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    className="text-white text-xl"
                  />
                </button>

                <UploadZipButton project={project} />
              </div>
            </div>
            <div className={`p-4 flex-1 ${!isGridView ? " gap-4" : ""}`}>
              <h3 className="text-lg font-bold text-gray-900 mb-3">
                {project.faultType || "مشروع"}
              </h3>

              <div
                className={`${
                  !isGridView ? "flex flex-wrap gap-2" : "flex flex-col gap-2"
                } text-sm text-gray-700`}
              >
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">القسم:</span>{" "}
                  {project.type || "غير محدد"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">رقم امر العمل:</span>{" "}
                  {project.faultNumber || project.orderNumber}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">نوع امر العمل:</span>{" "}
                  {project.workOrderType || "غير متاح"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">امر العمل:</span>{" "}
                  {project.orderType || "غير متاح"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">الفرع:</span>{" "}
                  {project.branchName || "غير متاح"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">المكتب:</span>{" "}
                  {project.office || "غير متاح"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">موقف المشروع:</span>{" "}
                  {SituationOptions.get(project.situation)?.label || "غير متاح"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">الحالة:</span>{" "}
                  {project.isArchived ? "تحت التنفيذ" : "تم التنفيذ"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">تاريخ استلام امر العمل:</span>{" "}
                  {new Date(project.receiveDateTime).toLocaleDateString()}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">الحي:</span>{" "}
                  {project.district || "غير متاح"}
                </p>

                <a
                  className="col-span-2 text-center text-blue-600 font-medium hover:underline"
                  href={project.coordinates || "#"}
                >
                  📍 الاحداثيات
                </a>
              </div>

              <div
                className={`flex gap-4  ${
                  isGridView && "mt-5"
                }  justify-center`}
              >
                <Link
                  to={
                    project.type === "الصيانه"
                      ? `/maintain-projects/${project.id}`
                      : project.type === "المشاريع الخاصة"
                      ? `/special-projects/${project.id}`
                      : project.type === "المشاريع"
                      ? `/request-projects/${project.id}`
                      : `/operations-maintenance/${project.id}`
                  }
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                >
                  تعديل الطلب
                </Link>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
                  onClick={() => handleDelete(project.id, project.type)}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompletedUi;
