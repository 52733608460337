import React, { useState, useEffect } from "react";
import "./AddAccount.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Url } from "../../function/FunctionApi";
import BranchSelect from "../../Component/SelectBranch/SelectBranch";

function AddAccount({ accountData }) {
  const [formData, setFormData] = useState({
    image: null,
    name: "",
    email: "",
    branch: "",
    phone: "",
    userType: "",
    password: "",
    displayName: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");

  useEffect(() => {
    if (accountData) {
      setFormData({
        image: accountData.userImage || null,
        name: accountData.userName || "",
        email: accountData.email || "",
        branch: accountData.branchName || "",
        phone: accountData.phone || "",
        userType: accountData.userType || "",

        password: accountData.password || "",
        displayName: accountData.displayName || "",
      });
    } else {
      resetForm();
    }
  }, [accountData]);

  const handleInputChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
    }
  };

  const resetForm = () => {
    setFormData({
      image: null,
      name: "",
      email: "",
      branch: "",
      phone: "",
      userType: "",
      password: "",
      displayName: "", // Reset displayName
    });
  };

  const handleSaveAccount = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const form = new FormData();
      let userTypeValue = formData.userType;

      if (accountData) {
        form.append("Email", accountData.email);
        form.append("DisplayName", formData.displayName); // Add DisplayName to the PUT request
        if (formData.name !== accountData.userName)
          form.append("UserName", formData.name);
        if (formData.branch !== accountData.branchName)
          form.append("BranchId", formData.branch);
        if (formData.userType !== accountData.userType)
          form.append("UserType", userTypeValue);
        if (formData.phone !== accountData.phone)
          form.append("PhoneNumber", formData.phone);
        if (formData.password) form.append("Password", formData.password);
        if (formData.image && formData.image !== accountData.userImage)
          form.append("UserImage", formData.image);

        const response = await axios.put(`${Url}Account/update-account`, form, {
          params: { userName: accountData.displayName }, // Pass displayName in the params
          headers: { "Content-Type": "multipart/form-data" },
        });
        handleResponse(response, "Account has been updated successfully!");
      } else {
        // Create a new account
        form.append("Email", formData.email);
        form.append("DisplayName", formData.displayName); // Add DisplayName to the POST request
        form.append("UserName", formData.name);
        form.append("BranchId", formData.branch);
        form.append("UserType", userTypeValue);
        form.append("PhoneNumber", formData.phone);
        form.append("Password", formData.password);
        if (formData.image) form.append("UserImage", formData.image);

        const response = await axios.post(`${Url}Account/register`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        handleResponse(response, "Account has been added successfully!");
      }
    } catch (error) {
      console.error("Error while saving account:", error);
      setModalContent(
        error.response?.data?.message ||
          "An unexpected error occurred. Please try again."
      );
      setModalVisible(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResponse = (response, successMessage) => {
    if (response.status === 200) {
      setModalContent(successMessage);
      window.location.reload();
    } else {
      setModalContent(
        response.data?.message ||
          "An error occurred while saving the account. Please try again."
      );
    }
    setModalVisible(true);
  };

  const isFormValid = () => {
    return (
      formData.name &&
      formData.email &&
      formData.branch &&
      formData.phone &&
      formData.userType &&
      (!accountData || formData.password) &&
      formData.displayName // Ensure displayName is also validated
    );
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalContent("");
  };

  return (
    <div className="add-account-container">
      <div className="profile-picture-container">
        <div className="profile-picture">
          {formData.image ? (
            typeof formData.image === "string" ? (
              <img src={formData.image} alt="Profile" />
            ) : (
              <img src={URL.createObjectURL(formData.image)} alt="Profile" />
            )
          ) : (
            <FontAwesomeIcon icon={faCamera} />
          )}
          <input type="file" accept="image/*" onChange={handleImageChange} />
        </div>
      </div>
      <div className="form-fields">
        <div className="form-group">
          <div>
            <label>الاسم (Username)</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>اسم العرض (الاستشاري)</label>
            <input
              type="text"
              name="displayName"
              value={formData.displayName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-group">
          <div>
            <label>البريد الإلكتروني</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              readOnly={!!accountData}
            />
          </div>
          <div>
            <label>رقم التليفون</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-group">
          <div>
            <label>نوع المستخدم</label>
            <select
              name="userType"
              value={formData.userType}
              onChange={handleInputChange}
            >
              <option value="">اختر نوع المستخدم</option>
              {localStorage.getItem("userType") === "admin" && (
                <>
                  {" "}
                  <option value="admin">مسؤول</option>
                  <option value="supervisor">مشرف</option>
                </>
              )}
              <option value="officemanager">مدير مكتب</option>

              <option value="eng">مهندس</option>
            </select>
          </div>
          <BranchSelect value={formData.branch} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <div>
            <label>كلمة المرور</label>
            <input
              type={accountData ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>
          <div></div>
        </div>
      </div>
      <button
        className="add-account-button"
        onClick={handleSaveAccount}
        disabled={!isFormValid() || isSubmitting}
      >
        {isSubmitting
          ? "جاري الإرسال..."
          : accountData
          ? "تعديل الحساب"
          : "إضافة حساب"}
      </button>
      {modalVisible && (
        <div className="modal-open">
          <div className="modal-content">
            <p>{modalContent}</p>
            <button onClick={closeModal} className="close-modal-button">
              إغلاق
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddAccount;
