import React, { useState, useEffect } from "react";
import axios from "../../api/client";

function ConsultantSupport({ handleChange, value }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("api/Account/accounts-eng");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching consultants:", error);
      }
    };

    fetchData();
  }, []); 

  return (
    <div className="flex flex-col gap-2 items-start w-full">
      <label htmlFor="consultantSupport">استشاري دعم</label>
      <select
        id="consultantSupport"
        name="ConsultantSupport"
        className="p-3 focus:outline-none border border-gray-300 rounded-md w-full"
        value={value || ""}
        onChange={(e) => handleChange(e)}
      >
        <option value="">اختر استشاري دعم</option>
        {data.map((option) => (
          <option key={option.id} value={option.id}>
            {option.userName}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ConsultantSupport;
