import React from "react";
import { domain } from "../../function/FunctionApi";

function EngChangesRequest({ data }) {
  if (!data || data.length === 0) {
    return <p className="text-gray-500 text-center">لا توجد تغييرات متاحة</p>;
  }

  return (
    <div className=" h-[90vh] overflow-y-scroll p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold text-gray-800 border-b pb-2 mb-4">
        سجل التغييرات
      </h2>
      <ul className="space-y-4">
        {data.map((change) => (
          <li key={change.id} className="p-4 border rounded-lg bg-gray-50">
            <div className="flex items-center gap-4">
              <img
                src={domain + change.userProfileImage}
                alt={change.userName}
                className="w-12 h-12 rounded-full border"
              />
              <div>
                <p className="font-semibold text-gray-700">{change.userName}</p>
                <p className="text-gray-500 text-sm">
                  {new Date(change.changeDate).toLocaleDateString("ar-EG")}
                </p>
              </div>
            </div>
            <p className="mt-2 text-gray-700">{change.changeDescription}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EngChangesRequest;
