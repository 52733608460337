import React, { useEffect, useState } from "react";
import axios from "axios";
import { Url } from "../../function/FunctionApi";

const SelectContractor = ({ value, onChange }) => {
  const [contractors, setContractors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch contractors from the API
    const fetchContractors = async () => {
      try {
        const response = await axios.get(`${Url}Contractor`); // Modify endpoint for contractors
        if (response.status === 200 && response.data.data) {
          setContractors(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching contractors:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContractors();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-start w-[48%]">
      <label>المقاول</label>
      <select
        name="Contractor"
        value={value}
        onChange={(e) => onChange(e)}
        required
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
      >
        <option value="" disabled>
          اختر المقاول
        </option>
        {contractors.map((contractor) => (
          <option key={contractor.id} value={contractor.name}>
            {contractor.name}
          </option>
        ))}
      </select>
    </div>
  );
};
export default SelectContractor;
