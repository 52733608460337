import React, { useEffect, useState } from "react";
import axios from "axios";
import { Url } from "../../function/FunctionApi";

const SelectDistrict = ({ value, onChange, officeName }) => {
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!officeName) {
      setDistricts([]); // Clear districts when officeName is empty
      return;
    }

    const fetchDistricts = async () => {
      try {
        setLoading(true);
        let response;
        if (officeName === "all") {
          response = await axios.get(`${Url}Neighborhood`);
        } else {
          response = await axios.get(
            `${Url}Office/GetByOffice?officeName=${officeName.trim()}`
          );
        }

        if (response.status === 200 && response.data.data) {
          setDistricts(response.data.data);
        } else {
          setDistricts([]);
        }
      } catch (error) {
        setDistricts([]);
        console.error("Error fetching districts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDistricts();
  }, [officeName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-start w-[48%]">
      <label>الحي</label>
      <select
        name="District"
        value={value}
        onChange={onChange}
        required
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
      >
        <option value="" disabled>
          اختر الحي
        </option>
        {districts.map((district) => (
          <option key={district.id} value={district.name}>
            {district.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectDistrict;
