import React, { useState } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import UploadZipButton from "../../Component/UploadZipButton/UploadZipButton";
function RequestsTable({
  filteredRequests,
  selectedRequests,
  handleCheckboxChange,
}) {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const getProjectTypeRoute = (orderType) => {
    switch (orderType) {
      case "العمليات":
        return "operation";
      case "المشاريع":
        return "newproject";
      case "الصيانة":
        return "maintains";
      default:
        return "privateproject";
    }
  };

  const sortedRequests = [...filteredRequests].sort((a, b) => {
    if (!sortConfig.key) return 0;

    const valueA = a[sortConfig.key] || "";
    const valueB = b[sortConfig.key] || "";

    if (typeof valueA === "string" && typeof valueB === "string") {
      return sortConfig.direction === "asc"
        ? valueA.localeCompare(valueB, "ar")
        : valueB.localeCompare(valueA, "ar");
    } else {
      return sortConfig.direction === "asc" ? valueA - valueB : valueB - valueA;
    }
  });

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <div className="overflow-x-auto h-[50vh] relative">
      <table className="w-full border-collapse border border-gray-300">
        <thead className="bg-green-700 text-white sticky top-0 z-10">
          <tr className="bg-green-700 text-white">
            <th className="p-1 text-sm text-center">اختيار</th>
            <th
              className="p-1 text-sm text-center cursor-pointer"
              onClick={() => handleSort("branchName")}
            >
              الفرع{" "}
              {sortConfig.key === "branchName" &&
                (sortConfig.direction === "asc" ? (
                  <FaSortUp className="inline" />
                ) : (
                  <FaSortDown className="inline" />
                ))}
            </th>
            <th
              className="p-1 text-sm text-center cursor-pointer"
              onClick={() => handleSort("office")}
            >
              المكتب{" "}
              {sortConfig.key === "office" &&
                (sortConfig.direction === "asc" ? (
                  <FaSortUp className="inline" />
                ) : (
                  <FaSortDown className="inline" />
                ))}
            </th>
            <th
              className="p-1 text-sm text-center cursor-pointer"
              onClick={() => handleSort("faultNumber")}
            >
              رقم أمر العمل{" "}
              {sortConfig.key === "faultNumber" &&
                (sortConfig.direction === "asc" ? (
                  <FaSortUp className="inline" />
                ) : (
                  <FaSortDown className="inline" />
                ))}
            </th>
            <th
              className="p-1 text-sm text-center cursor-pointer"
              onClick={() => handleSort("workOrderType")}
            >
              نوع العمل{" "}
              {sortConfig.key === "workOrderType" &&
                (sortConfig.direction === "asc" ? (
                  <FaSortUp className="inline" />
                ) : (
                  <FaSortDown className="inline" />
                ))}
            </th>
            <th className="p-1 text-sm text-center">وصف المشروع</th>
            <th
              className="p-1 text-sm text-center cursor-pointer"
              onClick={() => handleSort("estimatedValue")}
            >
              القيمة التقديرية{" "}
              {sortConfig.key === "estimatedValue" &&
                (sortConfig.direction === "asc" ? (
                  <FaSortUp className="inline" />
                ) : (
                  <FaSortDown className="inline" />
                ))}
            </th>
            <th
              className="p-1 text-sm text-center cursor-pointer"
              onClick={() => handleSort("receiveDateTime")}
            >
              تاريخ الاستلام{" "}
              {sortConfig.key === "receiveDateTime" &&
                (sortConfig.direction === "asc" ? (
                  <FaSortUp className="inline" />
                ) : (
                  <FaSortDown className="inline" />
                ))}
            </th>
            <th
              className="p-1 text-sm text-center cursor-pointer"
              onClick={() => handleSort("orderDate")}
            >
              تاريخ التنفيذ{" "}
              {sortConfig.key === "orderDate" &&
                (sortConfig.direction === "asc" ? (
                  <FaSortUp className="inline" />
                ) : (
                  <FaSortDown className="inline" />
                ))}
            </th>
            <th
              className="p-1 text-sm text-center cursor-pointer"
              onClick={() => handleSort("actualValue")}
            >
              القيمة الفعلية{" "}
              {sortConfig.key === "actualValue" &&
                (sortConfig.direction === "asc" ? (
                  <FaSortUp className="inline" />
                ) : (
                  <FaSortDown className="inline" />
                ))}
            </th>
            <th className="p-1 text-sm text-center">رقم المستخلص</th>
            <th className="p-1 text-sm text-center">حالة الطلب</th>
            <th className="p-1 text-sm text-center">موقف التنفيذ</th>
            <th className="p-1 text-sm text-center">تنزيل</th>
          </tr>
        </thead>
        <tbody>
          {sortedRequests.map((request, index) => (
            <tr
              key={index}
              className="border border-gray-300 text-center cursor-pointer"
              onClick={() =>
                navigate(
                  `/project/${getProjectTypeRoute(request.type)}/${request.id}`
                )
              }
            >
              <td className="p-2" onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={selectedRequests.includes(index)}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleCheckboxChange(index);
                  }}
                />
              </td>
              <td className="p-2">{request.branchName || "لا يوجد"}</td>
              <td className="p-2">{request.office || "لا يوجد"}</td>
              <td className="p-2">{request.faultNumber || "لا يوجد"}</td>
              <td className="p-2">{request.workOrderType || "لا يوجد"}</td>
              <td className="p-2">{request.workDescription || "لا يوجد"}</td>
              <td className="p-2">{request.estimatedValue || "لا يوجد"}</td>
              <td className="p-2">
                {request.receiveDateTime?.split("T")[0] || "لا يوجد"}
              </td>
              <td className="p-2">
                {request.orderDate?.split("T")[0] || "لا يوجد"}
              </td>
              <td className="p-2">{request.actualValue || "لا يوجد"}</td>
              <td className="p-2">{request.extractNumber || "لا يوجد"}</td>
              <td className="p-2">
                {request.situation === "finish"
                  ? "تم التنفيذ"
                  : request.situation === "notFinished"
                  ? "تحت التنفيذ"
                  : "جاري"}
              </td>
              <td className="p-2">
                {request.receiveDateTime &&
                !request.orderDate &&
                !request.actualValue &&
                !request.extractNumber
                  ? "تم تسليم المقاول"
                  : request.orderDate &&
                    request.receiveDateTime &&
                    !request.actualValue &&
                    !request.extractNumber
                  ? "تم الاستلام من المقاول"
                  : request.actualValue &&
                    request.orderDate &&
                    request.receiveDateTime &&
                    !request.extractNumber
                  ? "تم إصدار شهادة الإنجاز"
                  : request.extractNumber
                  ? "منتهي مستخلص"
                  : "لا يوجد"}
              </td>
              <td onClick={(e) => e.stopPropagation()}>
                <UploadZipButton project={request} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RequestsTable;
