import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useState } from "react";
import { FaDownload } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";

const UploadZipButton = ({ project }) => {
  const [isZipping, setIsZipping] = useState(false);
  const [error, setError] = useState(null);

  // Enhanced file name generator
  const generateFileName = (url, contentType) => {
    const randomNum = Math.floor(1000 + Math.random() * 9000);
    const cleanUrl = url.split('?')[0].split('#')[0];
    let extension = cleanUrl.split('.').pop().toLowerCase();
    
    // Determine extension from content type if URL doesn't have one
    if (!extension || extension.length > 5) {
      if (contentType.includes('pdf')) extension = 'pdf';
      else if (contentType.includes('jpeg')) extension = 'jpg';
      else if (contentType.includes('png')) extension = 'png';
      else extension = 'bin';
    }
    
    return `${randomNum}.${extension}`;
  };

  // Robust file fetcher with proper blob handling
  const fetchFile = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP ${response.status}`);
      
      const contentType = response.headers.get('content-type') || '';
      const blob = await response.blob();
      
      return {
        blob,
        contentType,
        url
      };
    } catch (err) {
      console.error(`Failed to fetch ${url}:`, err);
      throw err;
    }
  };

  const handleDownloadZip = async () => {
    if (!project || isZipping) return;
    
    setIsZipping(true);
    setError(null);
    
    const zip = new JSZip();
    const allFiles = [
      ...(project.modelPhotos || []),
      ...(project.safetyWastePhotos || []),
      ...(project.sitePhotos || []),
    ];

    try {
      // Process files sequentially for better reliability
      for (const file of allFiles) {
        if (!file?.url) continue;
        
        try {
          const { blob, contentType, url } = await fetchFile(file.url);
          const fileName = generateFileName(url, contentType);
          
          // For PDFs, use STORE compression (no compression)
          const options = contentType.includes('pdf') 
            ? { binary: true, compression: 'STORE' }
            : { binary: true };
          
          zip.file(fileName, blob, options);
        } catch (err) {
          console.error(`Error processing ${file.url}:`, err);
        }
      }

      // Generate ZIP with appropriate settings
      const content = await zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 6 },
      });

      saveAs(content, `project_${project.id}_files.zip`);
    } catch (error) {
      console.error("ZIP creation failed:", error);
      setError("Download failed. Some files may be corrupted.");
    } finally {
      setIsZipping(false);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <button
        className="bg-green-600 text-white p-2 rounded-full flex items-center gap-2 disabled:opacity-50"
        onClick={handleDownloadZip}
        disabled={isZipping}
        aria-label="Download project files as ZIP"
      >
        {isZipping ? (
          <ImSpinner2 className="animate-spin" />
        ) : (
          <FaDownload />
        )}
      </button>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  );
};

export default UploadZipButton;