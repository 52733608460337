import React from "react";

function RequestsHead() {
  return (
    <div className="hidden lg:flex lg:items-center mx-16 lg:flex-wrap justify-between my-12">
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        الفرع
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        المكتب
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        رقم امر العمل
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        نوع العمل{" "}
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        وصف المشروع{" "}
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        القيمه التقديريه{" "}
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        تاريخ الاستلام{" "}
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        تاريخ التنفيذ{" "}
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        القيمه الفعليه{" "}
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        رقم المستخلص{" "}
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        حاله الطلب{" "}
      </span>
      <span className="text-white bg-green-700 p-1 rounded-md font-semibold">
        موقف التنفيذ{" "}
      </span>
    </div>
  );
}

export default RequestsHead;
