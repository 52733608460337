import React, { useState, useEffect, useMemo } from "react";
import * as XLSX from "xlsx";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./SearchRequests.css";
import axios from "../../api/client";
import { Url } from "../../function/FunctionApi";
import { getCookie } from "../../Pages/Login/Login";
import { Link } from "react-router-dom";
import useExportToExcel from "../../hooks/ExportExcel";
import RequestCard from "../../Component/RequestCard/RequestCard";
import FilterationOrders from "../../Component/FilterationOrders/FilterationOrders";
import RequestsHead from "./RequestsHead";
import Modal from "./ModelPrintExport";
import usePrintProjectsHandler from "../../hooks/usePrintProjectsHandler";
import ExportPrintButtons from "./ExportPrintrBtns";
import Totals from "./Totals";
import RequestsTable from "../../Component/RequestCard/RequestCard";
import Swal from "sweetalert2";

// Modal component
const getProjectTypeRoute = (orderType) => {
  switch (orderType) {
    case "العمليات":
      return "operation";
    case "المشاريع":
      return "newproject";
    case "الصيانة":
      return "maintains";
    default:
      return "privateproject";
  }
};
const SearchRequests = () => {
  const [filters, setFilters] = useState({
    branch: "",
    office: "",
    requestNumber: "",
    situation: "",
    contractor: "",
    situation1: "",
    orderType: "",
    projectType: "",
    requestStatus: "",
    searchQuery: "",
    faultNumber: "",
    consultant: "",
    stationNumber: "",
    district: "",
    orderDate: "",
    receiveDate: "",
    actualValue: "",
    extractValue: "",
    estimatedValue: "",
    jobDescription: "",
  });

  const [apiData, setApiData] = useState([]);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userCookie = getCookie("user");
  const fetchData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(userCookie).token;
      const response = await axios.get(`/api/Admin/AllOrders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setApiData([
        ...(response.data.maintencesOrders || []),
        ...(response.data.privateProjects || []),
        ...(response.data.newProjects || []),
        ...(response.data.operationOrders || []),
      ]);
    } catch (err) {
      setError("فشل في تحميل البيانات، يرجى المحاولة لاحقاً.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [userCookie]);

  const filteredRequests = useMemo(() => {
    return apiData.filter((request) => {
      const {
        branch,
        requestNumber,
        contractor,
        projectType,
        requestStatus,
        startDate,
        endDate,
        searchQuery,
        consultant,
        stationNumber,
        district,
        situation,
        orderType,
        situation1,
        orderDate,
        receiveDate,
        extractValue,
        estimatedValue,
        jobDescription, // Changed from jobDescription to workDescription
        faultNumber,
        office, // Added office filter
        actualValue,
      } = filters;

      const matchesBranch =
        !branch || request.branchName?.toLowerCase() === branch.toLowerCase();
      const matchesRequestNumber =
        !requestNumber ||
        request.workOrderType?.toString().includes(requestNumber);
      const matchesContractor =
        !contractor ||
        request.contractor?.toLowerCase().includes(contractor.toLowerCase());
      const matchesProjectType =
        !projectType ||
        (typeof request.type === "string" &&
          request.type.toLowerCase().includes(projectType.toLowerCase()));
      const matchesRequestStatus =
        !requestStatus || request.isArchived?.toString() === requestStatus;
      const matchesConsultant =
        !consultant ||
        request.consultant?.toLowerCase().includes(consultant.toLowerCase());
      const matchesStationNumber =
        !stationNumber ||
        request.stationNumber?.toString().includes(stationNumber);
      const matchesDistrict =
        !district ||
        request.district?.toLowerCase().includes(district.toLowerCase());
      const matchesSituation =
        !situation ||
        request.situation?.toLowerCase().includes(situation.toLowerCase());

      const matchesOrderType =
        !orderType ||
        request.orderType?.toLowerCase().includes(orderType.toLowerCase());

      const matchesDate =
        (!startDate || new Date(request.orderDate) >= new Date(startDate)) &&
        (!endDate || new Date(request.orderDate) <= new Date(endDate));

      const matchesSearchQuery =
        !searchQuery ||
        Object.values(request).some((value) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );

      const matchesOffice =
        !office || request.office?.toLowerCase().includes(office.toLowerCase());

      const matchesWorkDescription =
        !jobDescription ||
        request.workDescription
          ?.toLowerCase()
          .includes(jobDescription.toLowerCase());

      const matchesFaultNumber =
        !faultNumber || request.faultNumber?.toString().includes(faultNumber);

      const matchesExtractNumber =
        !extractValue ||
        request.extractNumber?.toString().includes(extractValue);

      const matchesEstimatedValue =
        !estimatedValue ||
        request.estimatedValue?.toString().includes(estimatedValue);

      const matchesOrderDate =
        !orderDate ||
        new Date(request.orderDate).toISOString().startsWith(orderDate);

      const matchesReceiveDate =
        !receiveDate ||
        new Date(request.receiveDateTime).toISOString().startsWith(receiveDate);
      const matchesActualValue =
        !actualValue || request.actualValue?.toString().includes(actualValue);
      const matchesSituation1 =
        !situation1 ||
        (situation1 === "reciveOrder" &&
          request.receiveDateTime &&
          !request.orderDate &&
          !request.actualValue &&
          !request.extractNumber) ||
        (situation1 === "excuteOrder" &&
          request.orderDate &&
          !request.actualValue &&
          !request.extractNumber) ||
        (situation1 === "actualOrder" &&
          request.actualValue &&
          !request.extractNumber) ||
        (situation1 === "etractValue" && request.extractNumber);

      return (
        matchesBranch &&
        matchesRequestNumber &&
        matchesContractor &&
        matchesProjectType &&
        matchesRequestStatus &&
        matchesConsultant &&
        matchesStationNumber &&
        matchesDistrict &&
        matchesSituation &&
        matchesOrderType &&
        matchesDate &&
        matchesSearchQuery &&
        matchesOffice &&
        matchesWorkDescription &&
        matchesFaultNumber &&
        matchesExtractNumber &&
        matchesEstimatedValue &&
        matchesOrderDate &&
        matchesReceiveDate &&
        matchesSituation1 &&
        matchesActualValue
      );
    });
  }, [apiData, filters]);
  const { handlePrint } = usePrintProjectsHandler(
    selectedRequests,
    filteredRequests
  );

  console.log(filteredRequests);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };
  const { exportToExcel } = useExportToExcel(
    filteredRequests,
    selectedRequests
  );

  const handleCheckboxChange = (index) => {
    setSelectedRequests((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleSelectAll = () => {
    setSelectedRequests(
      selectedRequests.length === filteredRequests.length
        ? []
        : filteredRequests.map((_, index) => index)
    );
  };

  const exportToExcelPrint = () => {
    exportToExcel();
    setIsModalOpen(false);
  };

  if (loading) return <Skeleton count={10} height={50} />;
  if (error) return <div>{error}</div>;

  const renderFilterOptions = (key) => {
    const optionsMap = {
      branch: [
        { value: "", label: "الفرع" },
        { value: "الرياض", label: "الرياض" },
        { value: "حائل", label: "حائل" },
      ],
      projectType: [
        { value: "", label: "نوع المشروع" },
        { value: "ordersSubs", label: "مشتركين" },
        { value: "operationOrders", label: "عمليات وصيانة" },
        { value: "newProjects", label: "مشاريع" },
      ],
      requestStatus: [
        { value: "", label: "حالة الطلب" },
        { value: "true", label: "مؤرشف" },
        { value: "false", label: "غير مؤرشف" },
      ],
    };
    return optionsMap[key] || [];
  };

  const DeleteAll = async () => {
    const result = await Swal.fire({
      title: "هل أنت متأكد؟",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "نعم، احذف الكل!",
      cancelButtonText: "إلغاء",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "جارٍ الحذف...",
        html: "يرجى الانتظار",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        console.log("selected");
        console.log(selectedRequests);

        // Filter and map the data to get the desired output
        const dataDeleted = apiData
          .filter((item, index) => selectedRequests.includes(index)) // Filter based on selectedRequests
          .map((item) => ({
            orderId: item.id,
            type: getProjectTypeRoute(item.type), // Transform the item if selected
          }));

        const response = await axios.delete(
          "api/Search/delete-multiple-orders",
          {
            data: dataDeleted,
            headers: { "Content-Type": "application/json" }, // Make sure to send the 'data' in the correct format
          }
        );
        Swal.fire({
          title: "تم الحذف!",
          text: "تم حذف جميع العناصر بنجاح.",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });

        fetchData();
      } catch (error) {
        Swal.fire({
          title: "خطأ!",
          text: "حدث خطأ أثناء الحذف.",
          icon: "error",
        });
      }
    }
  };

  return (
    <div className="search-requests-container w-full lg:w-[90%] xl:w-[85%]">
      <div className="filters-container">
        <div className="select-all-checkbox">
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={
                selectedRequests.length === filteredRequests.length &&
                filteredRequests.length > 0
              }
              onChange={handleSelectAll}
              className="custom-checkbox"
            />
          </div>
        </div>
        {Object.keys(filters).map(
          (key) =>
            key !== "searchQuery" && (
              <>
                <FilterationOrders
                  key={key}
                  name={key}
                  value={filters[key]}
                  onChange={handleFilterChange}
                  options={renderFilterOptions(key)}
                />
              </>
            )
        )}
        <h1>{filteredRequests.length}</h1>
      </div>
      <RequestsTable
        handleCheckboxChange={handleCheckboxChange}
        selectedRequests={selectedRequests}
        filteredRequests={filteredRequests}
      />
      <Totals filteredRequests={filteredRequests} />
      <ExportPrintButtons
        DeleteAll={DeleteAll}
        selectedRequests={selectedRequests}
        setIsModalOpen={setIsModalOpen}
        setIsPrint={setIsPrint}
      />
      ;
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onExport={exportToExcelPrint}
        onPrint={handlePrint}
        isPrint={isPrint}
      />
    </div>
  );
};

export default SearchRequests;
