import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import { Url } from "../../function/FunctionApi";

function WorkDescriptionContent() {
  const [workDescriptions, setWorkDescriptions] = useState([]);
  const [description, setDescription] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [workOrderTypes, setWorkOrderTypes] = useState([]);
  const [selectedWorkOrderType, setSelectedWorkOrderType] = useState("");

  useEffect(() => {
    fetchData();
    fetchWorkOrderTypes();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Url}JobDescription`);
      setWorkDescriptions(response.data.data);
    } catch (error) {
      showErrorAlert("فشل تحميل البيانات. حاول مرة أخرى.");
    }
  };

  const fetchWorkOrderTypes = async () => {
    try {
      const response = await axios.get(`${Url}WorkOrderType`);
      setWorkOrderTypes(response.data.data);
    } catch (error) {
      showErrorAlert("فشل تحميل أنواع أوامر العمل. حاول مرة أخرى.");
    }
  };

  const showErrorAlert = (message) => {
    Swal.fire("خطأ", message, "error");
  };

  const showSuccessAlert = (message) => {
    Swal.fire("نجاح", message, "success");
  };

  const handleAddOrUpdate = async () => {
    if (!description || !selectedWorkOrderType) {
      showErrorAlert("يجب إدخال جميع الحقول.");
      return;
    }
    try {
      const data = { name: description, workOrderTypeId: selectedWorkOrderType };
      if (editingId) {
        await axios.put(`${Url}JobDescription/${editingId}`, data);
        showSuccessAlert("تم تحديث وصف العمل بنجاح.");
      } else {
        await axios.post(`${Url}JobDescription`, data);
        showSuccessAlert("تمت إضافة وصف العمل بنجاح.");
      }
      resetForm();
      fetchData();
    } catch (error) {
      showErrorAlert("حدث خطأ أثناء العملية. حاول مرة أخرى.");
    }
  };

  const handleEdit = (id) => {
    const workDescription = workDescriptions.find((w) => w.id === id);
    setEditingId(id);
    setDescription(workDescription.name);
    setSelectedWorkOrderType(workDescription.workOrderTypeId);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "تأكيد الحذف",
      text: "هل أنت متأكد أنك تريد حذف هذا الوصف؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "حذف",
      cancelButtonText: "إلغاء",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${Url}JobDescription/${id}`);
          showSuccessAlert("تم حذف وصف العمل بنجاح.");
          fetchData();
        } catch (error) {
          showErrorAlert("فشل حذف وصف العمل. حاول مرة أخرى.");
        }
      }
    });
  };

  const resetForm = () => {
    setDescription("");
    setEditingId(null);
    setSelectedWorkOrderType("");
  };

  return (
    <div className="p-6 min-h-screen">
      <div className="w-[95%] mx-auto bg-white shadow-sm rounded-lg p-8">
        <h4 className="text-center text-green-700 font-bold mb-6">
          إدارة أوصاف أوامر العمل
        </h4>
        <div className="mb-4">
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="إدخال وصف العمل"
            className="border p-2 w-full rounded-lg mb-2"
          />
          <select
            value={selectedWorkOrderType}
            onChange={(e) => setSelectedWorkOrderType(e.target.value)}
            className="border p-2 w-full rounded-lg mb-2"
          >
            <option value="">اختر نوع أمر العمل</option>
            {workOrderTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
          <button
            onClick={handleAddOrUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
          >
            {editingId ? "تحديث الوصف" : "إضافة وصف جديد"}
          </button>
        </div>
        <ul className="divide-gray-300">
          {workDescriptions.length > 0 ? (
            workDescriptions.map((workDescription) => (
              <li
                key={workDescription.id}
                className="flex justify-between items-center p-3 bg-gray-50 rounded-lg shadow-md mb-2 hover:bg-gray-100 transition duration-300"
              >
                <span className="text-gray-900 text-xl">{workDescription.name}</span>
                <div className="flex gap-4">
                  <button
                    onClick={() => handleEdit(workDescription.id)}
                    className="bg-blue-500 text-white rounded-full px-2 py-2 hover:bg-blue-600 transition duration-300"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleDelete(workDescription.id)}
                    className="bg-red-500 rounded-full text-white p-2 hover:bg-red-600 transition duration-300"
                  >
                    <FaTrash />
                  </button>
                </div>
              </li>
            ))
          ) : (
            <p className="text-center text-gray-500">لم يتم إضافة أي وصف بعد.</p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default WorkDescriptionContent;
