import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import { Url } from "../../function/FunctionApi";

function ContractorsContent() {
  const [contractors, setContractors] = useState([]);
  const [name, setName] = useState("");
  const [editingId, setEditingId] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Url}Contractor`);
      setContractors(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "فشل تحميل البيانات. حاول مرة أخرى.",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle adding or updating a contractor
  const handleAddOrUpdate = async (name, id = null) => {
    try {
      let response;
      if (id) {
        // Update existing contractor
        response = await axios.put(`${Url}Contractor/${id}`, { name });
        setContractors(
          contractors.map((contractor) =>
            contractor.id === id ? { ...contractor, name } : contractor
          )
        );
        Swal.fire({
          icon: "success",
          title: "تم التحديث",
          text: "تم تحديث المقاول بنجاح.",
        });
      } else {
        response = await axios.post(`${Url}Contractor`, { name });
        setContractors([...contractors, { id: response.data.id, name }]);
        Swal.fire({
          icon: "success",
          title: "تم الإضافة",
          text: "تم إضافة المقاول بنجاح.",
        });

        fetchData();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: `فشل ${id ? "تحديث" : "إضافة"} المقاول. حاول مرة أخرى.`,
      });
    }
    setName(""); // Clear input after adding/updating
    setEditingId(null);
  };

  // Handle editing a contractor
  const handleEdit = (id) => {
    setEditingId(id);
    const contractor = contractors.find((c) => c.id === id);
    setName(contractor.name);
    Swal.fire({
      title: "تحديث المقاول",
      input: "text",
      inputValue: contractor.name,
      showCancelButton: true,
      confirmButtonText: "تحديث",
      cancelButtonText: "إلغاء",
      preConfirm: (newName) => {
        if (newName) {
          handleAddOrUpdate(newName, id);
        } else {
          Swal.showValidationMessage("الاسم مطلوب");
        }
      },
    });
  };

  // Handle deleting a contractor
  const handleDelete = (id) => {
    Swal.fire({
      title: "تأكيد الحذف",
      text: "هل أنت متأكد أنك تريد حذف هذا المقاول؟ لا يمكن التراجع عن هذا الإجراء.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "حذف",
      cancelButtonText: "إلغاء",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${Url}Contractor/${id}`);
          setContractors(
            contractors.filter((contractor) => contractor.id !== id)
          );
          Swal.fire({
            icon: "success",
            title: "تم الحذف",
            text: "تم حذف المقاول بنجاح.",
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: "فشل حذف المقاول. حاول مرة أخرى.",
          });
        }
      }
    });
  };

  return (
    <div className="p-6  min-h-screen">
      <div className=" w-[95%] mx-auto bg-white shadow-sm rounded-lg p-8">
        <div className="mb-6 p-4 mx-auto flex items-center justify-between">
          <h4 className="text-center text-green-700 font-bold mb-6">
            إدارة المقاولين
          </h4>
          <button
            onClick={() => {
              Swal.fire({
                title: "إضافة مقاول",
                input: "text",
                inputPlaceholder: "اسم المقاول",
                showCancelButton: true,
                confirmButtonText: "إضافة",
                cancelButtonText: "إلغاء",
                preConfirm: (newName) => {
                  if (newName) {
                    handleAddOrUpdate(newName);
                  } else {
                    Swal.showValidationMessage("الاسم مطلوب");
                  }
                },
              });
            }}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            إضافة مقاول
          </button>
        </div>
        <div className="p-3">
          {contractors.length > 0 ? (
            <ul className="divide-gray-300">
              {contractors.map((contractor) => (
                <li
                  key={contractor.id}
                  className="flex justify-between items-center p-1 px-4 bg-gray-50 rounded-lg shadow-md mb-2 hover:bg-gray-100 transition duration-300"
                >
                  <div>
                    <h6 className="font-medium text-gray-900 text-xl">
                      {contractor.name}
                    </h6>
                  </div>
                  <div className="flex gap-4">
                    <button
                      onClick={() => handleEdit(contractor.id)}
                      className="!bg-blue-500 text-white rounded-full px-2 py-2 hover:bg-blue-600 transition duration-300"
                    >
                      <FaEdit className="" />
                    </button>
                    <button
                      onClick={() => handleDelete(contractor.id)}
                      className="bg-red-500 rounded-full text-white p-2 hover:bg-red-600 transition duration-300"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-center text-gray-500">
              لم يتم إضافة أي مقاول بعد.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContractorsContent;
