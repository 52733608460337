// SituationOptions.js

const SituationOptions = new Map();

SituationOptions.set("", { label: "اختر الحالة", disabled: true });
SituationOptions.set("pending", { label: "جاري" });
SituationOptions.set("finish", { label: "منتهي" });
SituationOptions.set("notFinished", { label: "لم يتم التنفيذ" });
SituationOptions.set(
  "contractor_delivered",
  { label: "تم تسليم المقاول (الأوامر التي تم وضع لها تاريخ تنفيذ)" }
);
SituationOptions.set(
  "contractor_received",
  { label: "تم الاستلام من المقاول (الأوامر التي تم وضع لها تاريخ تنفيذ)" }
);
SituationOptions.set(
  "certificate_issued",
  { label: "تم إصدار شهادة إنجاز (الأوامر التي تم وضع لها قيمة فعلية)" }
);
SituationOptions.set(
  "final_statement",
  { label: "منتهي مستخلص (الأوامر التي تم وضع لها رقم مستخلص)" }
);

export default SituationOptions;
