import { React } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import ContractorsContent from "../../Component/Contractors/Contractors";

function Contractors() {
  return (
    <div className="apDiv">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>

        <ContractorsContent />
      </div>
    </div>
  );
}

export default Contractors;
