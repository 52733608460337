import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiOutlineFileDone,
  AiOutlineClockCircle,
} from "react-icons/ai"; // Import different icons for variety

const StatusSelection = ({ setChoose }) => {
  const navigate = useNavigate(); // Initialize navigate function

  // Function to handle URL updates based on selected status
  const handleStatusChange = (status) => {
    setChoose(true);
    navigate({
      pathname: window.location.pathname, // Retain the current path
      search: `?status=${status}`, // Add the status query parameter
    });
  };

  return (
    <div className="w-full flex justify-center items-center flex-wrap min-h-[60vh] gap-10">
      <div
        onClick={() => handleStatusChange('completed')} // Pass 'true' for "تم التنفيذ"
        className="flex flex-col items-center cursor-pointer hover:scale-105 transition-all bg-green-600 text-white p-6 rounded-lg shadow-lg w-[250px] h-[200px]"
      >
        <AiOutlineCheckCircle className="h-16 w-16 mb-4" />
        <h2 className="text-xl font-semibold">تم التنفيذ</h2>
        <p className="text-sm">الطلبات المكتملة</p>
      </div>

      <div
        onClick={() => handleStatusChange('notcompleted')} // Pass 'false' for "تحت التنفيذ"
        className="flex flex-col items-center cursor-pointer hover:scale-105 transition-all bg-orange-600 text-white p-6 rounded-lg shadow-lg w-[250px] h-[200px]"
      >
        <AiOutlineClockCircle className="h-16 w-16 mb-4" />
        <h2 className="text-xl font-semibold">تحت التنفيذ</h2>
        <p className="text-sm">الطلبات قيد المعالجة</p>
      </div>

      {localStorage.getItem("userType") === "officemanager" && (
        <>
          <div
            onClick={() => handleStatusChange('approved')} // Pass 'true' for "تم الاعتماد"
            className="flex flex-col items-center cursor-pointer hover:scale-105 transition-all bg-blue-600 text-white p-6 rounded-lg shadow-lg w-[250px] h-[200px]"
          >
            <AiOutlineFileDone className="h-16 w-16 mb-4" />
            <h2 className="text-xl font-semibold">تم الاعتماد</h2>
            <p className="text-sm">الطلبات المعتمدة</p>
          </div>

          <div
            onClick={() => handleStatusChange('udc')} // Pass 'false' for "تم الرفع على UDS"
            className="flex flex-col items-center cursor-pointer hover:scale-105 transition-all bg-purple-600 text-white p-6 rounded-lg shadow-lg w-[250px] h-[200px]"
          >
            <AiOutlineExclamationCircle className="h-16 w-16 mb-4" />
            <h2 className="text-xl font-semibold">تم الرفع على UDS</h2>
            <p className="text-sm">الطلبات في الانتظار</p>
          </div>
        </>
      )}
    </div>
  );
};

export default StatusSelection;
