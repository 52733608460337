import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import UploadZipButton from "../../Component/UploadZipButton/UploadZipButton";

import Banner from "../../Component/SubmitApplicationComp/Banner";
import Form from "./Form";
import img from "../../Image/Rectangle.png";
import { fetchDataWithRetries, Url } from "../../function/FunctionApi";
import EngChangesRequest from "../../Component/EngChangesRequest/EngChangesRequest";

function SpecialProjects({ userData }) {
  const { id } = useParams();
  const projectId = id ? Number(id) : null;

  const [apiData, setApiData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(!!projectId);

  // State for private project changes
  const [privateChanges, setPrivateChanges] = useState(null);
  const [loadingChanges, setLoadingChanges] = useState(!!projectId);
  const [errorChanges, setErrorChanges] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!projectId || !userData?.token) return; 

    const fetchProjectData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${Url}/PrivateProject/get-privateProjectByid/?id=${projectId}`,
          {
            headers: { Authorization: `Bearer ${userData.token}` },
          }
        );

        console.log("API Response:", response.data);
        setApiData(response.data?.data || null);
      } catch (err) {
        console.error("Error fetching project data:", err);
        setError("حدث خطأ أثناء جلب بيانات المشروع. الرجاء المحاولة لاحقًا.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [projectId, userData]);

  useEffect(() => {
    if (!projectId) return;

    const fetchPrivateChanges = async () => {
      setLoadingChanges(true);
      setErrorChanges(null);

      try {
        await fetchDataWithRetries(
          `Search/${projectId}/changes?projectType=private&type=private`,
          setPrivateChanges
        );
      } catch (err) {
        console.error("Error fetching private changes:", err);
        setErrorChanges("فشل تحميل تغييرات المشروع.");
      } finally {
        setLoadingChanges(false);
      }
    };

    fetchPrivateChanges();
  }, [projectId]);

  return (
    <>
      <Banner
        img={img}
        title="مشاريع خاصة"
        Description="قم بإضافة طلبك وتأكد من إدخال المعلومات بشكل صحيح"
      />

      <div className="flex justify-center flex-row-reverse items-start gap-5 flex-wrap">
        <div className="w-full lg:w-2/3">

        {apiData && (
            <div className="my-2">
              <UploadZipButton project={apiData} />
            </div>
          )}
          {loading ? (
            <div className="text-center text-blue-500 font-semibold animate-pulse">
              جاري تحميل بيانات المشروع...
            </div>
          ) : error ? (
            <div className="text-center text-red-500 font-bold">{error}</div>
          ) : (
            <Form userData={userData} apiData={apiData} isUpdate={!!projectId} />
          )}
        </div>

        {projectId && (
          <div className="">
            {loadingChanges ? (
              <div className="text-center text-blue-500 font-semibold animate-pulse">
                جاري تحميل تغييرات المشروع...
              </div>
            ) : errorChanges ? (
              <div className="text-center text-red-500 font-bold">
                {errorChanges}
              </div>
            ) : (
              <EngChangesRequest data={privateChanges} />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default SpecialProjects;
